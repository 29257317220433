<template>
    <div>
        <CRow>
            <CCol md="2">
                <CButton
                class="btnPrincipalVapp mb-1"
                style="width:auto"
                variant="ghost"
                size="sm"
                color="success"
                @click="actualizarFactores"
                :disabled="!habilitadoTraspaso || isAcualizandoIndices">
                <CIcon v-show="isAcualizandoIndices" :content="$options.freeSet.cilLoopCircular" class="imgr" /> Traspasar indicadores a mi Base de datos
            </CButton>
            </CCol>
            <CCol>
                <date-picker
                :editable="false"
                :clearable="false"
                default-panel="year"
                type="month"
                class="mb-2"
                :lang="langRetencionesPicker" 
                v-model="mesSelected2"
                :disabled-date="disabledMes"
                :confirm="false" 
                :confirm-text="'Aceptar'"
                @change="seleccionarPeriodo"
                ></date-picker>
            </CCol>
           
        </CRow>
        <hr>
        <div class="container" v-show="isCargandoInfo">
            <center>
                <img src="/img/logo2.png" size="custom-size" :height="50" class="imgr mt-3 mb-3" /> <h2>Cargando Información...</h2>
            </center>
        </div>
        <div class="container" v-show="!isCargandoInfo">
            <!-- <h1 style="color: #29235C;"> <strong> Indicadores Previsionales: <span style="color: #5D1F4A;" id="fechaIndicador"></span></strong></h1> -->
            <div class="text-center"> <h2 style="color: #29235C;"> <strong> Indicadores Previsionales </strong> <strong> <span style="color: #5D1F4A;" id="fechaIndicador" class="text-capitalize">{{fechaIndicador}}</span> </strong>  </h2></div>
            <!--<div class="text-center"> <h3> <strong> <span style="color: #5D1F4A;" id="fechaIndicador" class="text-capitalize">{{fechaIndicador}}</span> </strong> </h3> </div>-->
            <div class="text-center mb-2"> <strong> <span style="color: #0b7689;" id="cotAPagar" class="text-capitalize">{{cotAPagar}}</span> </strong></div>
            <div class="choseus-items">
                <div class="row">
                    <div class="col-lg-6">
                        <table class="table table-striped table-sm table-bordered">
                            <thead style="background-color: #29235C;color: #fff;">
                                <tr><th colspan="2" class="text-center">Valor UF</th></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="col-lg-8"><span id="fechaUFactual">{{indicadoresGeneral.fechaUFactual.formato}}</span></td>
                                    <td class="col-lg-4"><span id="UFactual" :class="(!indicadoresGeneral.UFactual.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.UFactual.formato}}</span>
                                        <span v-show="!indicadoresGeneral.UFactual.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.UFactual.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="col-lg-8"><span id="fechaUFanterior">{{indicadoresGeneral.fechaUFanterior.formato}}</span></td>
                                    <td class="col-lg-4"><span id="UFanterior" :class="(!indicadoresGeneral.UFanterior.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.UFanterior.formato}}</span>
                                        <span v-show="!indicadoresGeneral.UFanterior.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.UFanterior.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                        
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-sm table-bordered">
                            <thead style="background-color: #29235C;color: #fff;">
                                <tr>
                                <th colspan="2" class="text-center">Rentas Topes Imponibles</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Para afiliados a una AFP (<span id="RTI1">{{indicadoresGeneral.RTI1.formato}}</span> UF):</td>
                                    <td class="text-center"><span id="PRTI1" :class="(!indicadoresGeneral.PRTI1.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.PRTI1.formato}}</span>
                                        <span v-show="!indicadoresGeneral.PRTI1.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.PRTI1.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Para afiliados al IPS (ex INP) (<span id="RTI2">{{indicadoresGeneral.RTI2.formato}}</span> UF):</td>
                                    <td class="text-center"><span id="PRTI2" :class="(!indicadoresGeneral.PRTI2.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.PRTI2.formato}}</span>
                                        <span v-show="!indicadoresGeneral.PRTI2.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.PRTI2.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                   </td>
                                </tr>
                                <tr>
                                    <td>Para Seguro de Cesantía (<span id="RTI3">{{indicadoresGeneral.RTI3.formato}}</span> UF):</td>
                                    <td class="text-center"><span id="PRTI3" :class="(!indicadoresGeneral.PRTI3.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.PRTI3.formato}}</span>
                                        <span v-show="!indicadoresGeneral.PRTI3.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.PRTI3.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-sm table-bordered">
                            <thead style="background-color: #29235C;color: #fff;">
                                <tr>
                                    <th colspan="2" class="text-center">Rentas Mínimas Imponibles</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Trab. Dependientes e Independientes:</td>
                                    <td class="text-center"><span id="RMI1" :class="(!indicadoresGeneral.RMI1.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.RMI1.formato}}</span>
                                        <span v-show="!indicadoresGeneral.RMI1.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.RMI1.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Menores de 18 y Mayores de 65:</td>
                                    <td class="text-center"><span id="RMI2" :class="(!indicadoresGeneral.RMI2.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.RMI2.formato}}</span>
                                        <span v-show="!indicadoresGeneral.RMI2.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.RMI2.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Trabajadores de Casa Particular:</td>
                                    <td class="text-center"><span id="RMI3" :class="(!indicadoresGeneral.RMI3.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.RMI3.formato}}</span>
                                        <span v-show="!indicadoresGeneral.RMI3.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.RMI3.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Para fines no remuneracionales:</td>
                                    <td class="text-center"><span id="RMI4" :class="(!indicadoresGeneral.RMI4.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.RMI4.formato}}</span>
                                        <span v-show="!indicadoresGeneral.RMI4.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.RMI4.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-sm table-bordered">
                            <thead style="background-color: #29235C;color: #fff;">
                                <tr>
                                    <th colspan="2" class="text-center">Ahorro Previsional Voluntario (APV)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Tope Mensual (<span id="APVtopeMensual">{{indicadoresGeneral.APVtopeMensual.formato}}</span> UF):</td>
                                    <td class="text-center"><span id="APVtopem" :class="(!indicadoresGeneral.APVtopem.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.APVtopem.formato}}</span>
                                        <span v-show="!indicadoresGeneral.APVtopem.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.APVtopem.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tope Anual (<span id="APVtopeAnual">{{indicadoresGeneral.APVtopeAnual.formato}}</span> UF):</td>
                                    <td class="text-center"><span id="APVtopea" :class="(!indicadoresGeneral.APVtopea.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.APVtopea.formato}}</span>
                                        <span v-show="!indicadoresGeneral.APVtopea.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.APVtopea.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-sm table-bordered">
                            <thead style="background-color: #29235C;color: #fff;">
                                <tr>
                                    <th colspan="2" class="text-center">Depósito Convenido</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Tope Anual (<span id="DepoUF">{{indicadoresGeneral.DepoUF.formato}}</span> UF):</td>
                                    <td class="text-center"><span id="DepoUFpesos" :class="(!indicadoresGeneral.DepoUFpesos.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.DepoUFpesos.formato}}</span>
                                        <span v-show="!indicadoresGeneral.DepoUFpesos.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.DepoUFpesos.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-sm table-bordered">
                            <thead style="background-color: #29235C;color: #fff;">
                                <tr>
                                    <th colspan="3" class="text-center">Seguro de Cesantía (AFC)</th>
                                </tr>
                                <tr>
                                    <th rowspan="2" class="text-center align-middle">Contrato</th>
                                    <th colspan="2" class="text-center">Financiamiento</th>
                                </tr>
                                <tr>
                                    <th class="text-center">Empleador</th>
                                    <th class="text-center">Trabajador</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Plazo Indefinido</td>
                                    <td class="text-center"><span id="PIemp" :class="(!indicadoresGeneral.PIemp.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.PIemp.formato}}</span> R.I.
                                        <span v-show="!indicadoresGeneral.PIemp.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.PIemp.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="PItra" :class="(!indicadoresGeneral.PItra.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.PItra.formato}}</span> R.I.
                                        <span v-show="!indicadoresGeneral.PItra.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.PItra.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Plazo Fijo</td>
                                    <td class="text-center"><span id="PFemp" :class="(!indicadoresGeneral.PFemp.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.PFemp.formato}}</span> R.I.
                                        <span v-show="!indicadoresGeneral.PFemp.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.PFemp.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="PFtra" :class="(!indicadoresGeneral.PFtra.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.PFtra.formato}}</span> ---
                                        <span v-show="!indicadoresGeneral.PFtra.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.PFtra.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Plazo Indefinido 11 años o más</td>
                                    <td class="text-center"><span id="PInemp" :class="(!indicadoresGeneral.PInemp.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.PInemp.formato}}</span> R.I.
                                        <span v-show="!indicadoresGeneral.PInemp.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.PInemp.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="PIntra" :class="(!indicadoresGeneral.PIntra.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.PIntra.formato}}</span> ---
                                        <span v-show="!indicadoresGeneral.PIntra.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.PIntra.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Trabajador de Casa Particular</td>
                                    <td class="text-center"><span id="Tcpemp" :class="(!indicadoresGeneral.Tcpemp.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.Tcpemp.formato}}</span> R.I.
                                        <span v-show="!indicadoresGeneral.Tcpemp.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.Tcpemp.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="Tcptra" :class="(!indicadoresGeneral.Tcptra.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.Tcptra.formato}}</span> ---
                                        <span v-show="!indicadoresGeneral.Tcptra.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.Tcptra.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-lg-6">
                        <table class="table table-striped table-sm table-bordered">
                            <thead style="background-color: #29235C;color: #fff;">
                                <tr>
                                <th class="text-center">Valor</th>
                                <th class="text-center">UTM</th>
                                <th class="text-center">UTA</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center"><span id="UTMfec" :class="(!indicadoresGeneral.UTMfec.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.UTMfec.formato}}</span>
                                        <span v-show="!indicadoresGeneral.UTMfec.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.UTMfec.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="UTM" :class="(!indicadoresGeneral.UTM.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.UTM.formato}}</span>
                                        <span v-show="!indicadoresGeneral.UTM.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.UTM.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="UTA" :class="(!indicadoresGeneral.UTA.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.UTA.formato}}</span>
                                        <span v-show="!indicadoresGeneral.UTA.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.UTA.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-sm table-bordered">
                            <thead style="background-color: #29235C;color: #fff;">
                                <tr>
                                    <th rowspan="4" class="text-center align-middle">AFP</th>
                                    <th colspan="3" class="text-center">Tasa Cotización Obligatorio AFP</th>
                                </tr>
                                <tr>
                                    <th colspan="3" class="text-center">Tasa AFP Trabajadores</th>
                                </tr>
                                <tr>
                                    <th colspan="2" class="text-center">Dependientes</th>
                                    <th class="text-center">Independientes</th>
                                </tr>
                                <tr>
                                    <th class="text-center">Tasa AFP</th>
                                    <th class="text-center">SIS   (1)   (2)</th>
                                    <th class="text-center">Tasa AFP   (3)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center">Capital</td>
                                    <td class="text-center"><span id="DTasaAFPCa" :class="(!indicadoresGeneral.DTasaAFPCa.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.DTasaAFPCa.formato}}</span>
                                        <span v-show="!indicadoresGeneral.DTasaAFPCa.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.DTasaAFPCa.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="STasaAFPCa" :class="(!indicadoresGeneral.STasaAFPCa.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.STasaAFPCa.formato}}</span>
                                        <span v-show="!indicadoresGeneral.STasaAFPCa.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.STasaAFPCa.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="ITasaAFPCa" :class="(!indicadoresGeneral.ITasaAFPCa.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.ITasaAFPCa.formato}}</span>
                                        <span v-show="!indicadoresGeneral.ITasaAFPCa.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.ITasaAFPCa.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">Cuprum</td>
                                    <td class="text-center"><span id="DTasaAFPCu" :class="(!indicadoresGeneral.DTasaAFPCu.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.DTasaAFPCu.formato}}</span>
                                        <span v-show="!indicadoresGeneral.DTasaAFPCu.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.DTasaAFPCu.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="STasaAFPCu" :class="(!indicadoresGeneral.STasaAFPCu.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.STasaAFPCu.formato}}</span>
                                        <span v-show="!indicadoresGeneral.STasaAFPCu.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.STasaAFPCu.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="ITasaAFPCu" :class="(!indicadoresGeneral.ITasaAFPCu.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.ITasaAFPCu.formato}}</span>
                                        <span v-show="!indicadoresGeneral.ITasaAFPCu.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.ITasaAFPCu.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">Habitat</td>
                                    <td class="text-center"><span id="DTasaAFPH" :class="(!indicadoresGeneral.DTasaAFPH.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.DTasaAFPH.formato}}</span>
                                        <span v-show="!indicadoresGeneral.DTasaAFPH.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.DTasaAFPH.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="STasaAFPH" :class="(!indicadoresGeneral.STasaAFPH.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.STasaAFPH.formato}}</span>
                                        <span v-show="!indicadoresGeneral.STasaAFPH.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.STasaAFPH.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="ITasaAFPH" :class="(!indicadoresGeneral.ITasaAFPH.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.ITasaAFPH.formato}}</span>
                                        <span v-show="!indicadoresGeneral.ITasaAFPH.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.ITasaAFPH.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">PlanVital</td>
                                    <td class="text-center"><span id="DTasaAFPPV" :class="(!indicadoresGeneral.DTasaAFPPV.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.DTasaAFPPV.formato}}</span>
                                        <span v-show="!indicadoresGeneral.DTasaAFPPV.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.DTasaAFPPV.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="STasaAFPPV" :class="(!indicadoresGeneral.STasaAFPPV.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.STasaAFPPV.formato}}</span>
                                        <span v-show="!indicadoresGeneral.STasaAFPPV.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.STasaAFPPV.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="ITasaAFPPV" :class="(!indicadoresGeneral.ITasaAFPPV.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.ITasaAFPPV.formato}}</span>
                                        <span v-show="!indicadoresGeneral.ITasaAFPPV.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.ITasaAFPPV.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">Provida</td>
                                    <td class="text-center"><span id="DTasaAFPP" :class="(!indicadoresGeneral.DTasaAFPP.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.DTasaAFPP.formato}}</span>
                                        <span v-show="!indicadoresGeneral.DTasaAFPP.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.DTasaAFPP.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="STasaAFPP" :class="(!indicadoresGeneral.STasaAFPP.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.STasaAFPP.formato}}</span>
                                        <span v-show="!indicadoresGeneral.STasaAFPP.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.STasaAFPP.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="ITasaAFPP" :class="(!indicadoresGeneral.ITasaAFPP.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.ITasaAFPP.formato}}</span>
                                        <span v-show="!indicadoresGeneral.ITasaAFPP.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.ITasaAFPP.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">Modelo</td>
                                    <td class="text-center"><span id="DTasaAFPM" :class="(!indicadoresGeneral.DTasaAFPM.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.DTasaAFPM.formato}}</span>
                                        <span v-show="!indicadoresGeneral.DTasaAFPM.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.DTasaAFPM.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="STasaAFPM" :class="(!indicadoresGeneral.STasaAFPM.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.STasaAFPM.formato}}</span>
                                        <span v-show="!indicadoresGeneral.STasaAFPM.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.STasaAFPM.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="ITasaAFPM" :class="(!indicadoresGeneral.ITasaAFPM.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.ITasaAFPM.formato}}</span>
                                        <span v-show="!indicadoresGeneral.ITasaAFPM.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.ITasaAFPM.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">Uno</td>
                                    <td class="text-center"><span id="DTasaAFPU" :class="(!indicadoresGeneral.DTasaAFPU.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.DTasaAFPU.formato}}</span>
                                        <span v-show="!indicadoresGeneral.DTasaAFPU.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.DTasaAFPU.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="STasaAFPU" :class="(!indicadoresGeneral.STasaAFPU.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.STasaAFPU.formato}}</span>
                                        <span v-show="!indicadoresGeneral.STasaAFPU.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.STasaAFPU.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="ITasaAFPU" :class="(!indicadoresGeneral.ITasaAFPU.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.ITasaAFPU.formato}}</span>
                                        <span v-show="!indicadoresGeneral.ITasaAFPU.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.ITasaAFPU.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-sm table-bordered">
                            <thead style="background-color: #29235C;color: #fff;">
                                <tr>
                                    <th rowspan="2" class="text-center align-middle">Tramo</th>
                                    <th colspan="2" class="text-center">Asignación Familiar</th>
                                </tr>
                                <tr>
                                    <th class="text-center">Monto</th>
                                    <th class="text-center">Requisito de Renta</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center">1   (A)</td>
                                    <td class="text-center"><span id="MontoAFa">{{indicadoresGeneral.MontoAFa.formato}}</span>
                                        <span v-show="!indicadoresGeneral.MontoAFa.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.MontoAFa.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center">Renta<span id="Primont">{{indicadoresGeneral.Primont.formato}}</span>
                                        <span v-show="!indicadoresGeneral.Primont.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.Primont.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">2   (B)</td>
                                    <td class="text-center"><span id="MontoAFb">{{indicadoresGeneral.MontoAFb.formato}}</span>
                                        <span v-show="!indicadoresGeneral.MontoAFb.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.MontoAFb.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center">Renta > <span id="Primontb">{{indicadoresGeneral.Primontb.formato}}</span> <span id="Secmont">{{indicadoresGeneral.Secmont.formato}}</span>
                                        <span v-show="!indicadoresGeneral.Secmont.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.Secmont.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">3   (C)</td>
                                    <td class="text-center"><span id="MontoAFc">{{indicadoresGeneral.MontoAFc.formato}}</span>
                                        <span v-show="!indicadoresGeneral.MontoAFc.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.MontoAFc.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center">Renta > <span id="Secmontb">{{indicadoresGeneral.Secmontb.formato}}</span>  <span id="Termont">{{indicadoresGeneral.Termont.formato}}</span>
                                        <span v-show="!indicadoresGeneral.Termont.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.Termont.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">4   (D)</td>
                                    <td class="text-center"><span id="MontoAFd">{{indicadoresGeneral.MontoAFd.formato}}</span>---
                                        <span v-show="!indicadoresGeneral.MontoAFd.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.MontoAFd.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center">Renta > <span id="Termontb">{{indicadoresGeneral.Termontb.formato}}</span>
                                        <span v-show="!indicadoresGeneral.Termontb.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.Termontb.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-sm table-bordered">
                            <thead style="background-color: #29235C;color: #fff;">
                                <tr>
                                    <th colspan="4" class="text-center">Cotización para Trabajos Pesados</th>
                                </tr>
                                <tr>
                                    <th colspan="2" class="text-center">Calificación</th>
                                    <th colspan="2" class="text-center">Financiamiento</th>
                                </tr>
                                <tr>
                                    <th colspan="2" class="text-center">Puesto de Trabajo</th>
                                    <th class="text-center">Empleador</th>
                                    <th class="text-center">Trabajador</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="col-lg-8">Trabajo pesado</td>
                                    <td class="col-lg-4"><span id="Tpc" :class="(!indicadoresGeneral.Tpc.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.Tpc.formato}}</span>
                                        <span v-show="!indicadoresGeneral.Tpc.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.Tpc.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="Tpe" :class="(!indicadoresGeneral.Tpe.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.Tpe.formato}}</span> R.I.
                                        <span v-show="!indicadoresGeneral.Tpe.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.Tpe.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="Tpt" :class="(!indicadoresGeneral.Tpt.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.Tpt.formato}}</span> R.I.
                                        <span v-show="!indicadoresGeneral.Tpt.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.Tpt.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="col-lg-8">Trabajo menos pesado</td>
                                    <td class="col-lg-4"><span id="Tmpc" :class="(!indicadoresGeneral.Tmpc.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.Tmpc.formato}}</span>
                                        <span v-show="!indicadoresGeneral.Tmpc.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.Tmpc.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="Tmpe" :class="(!indicadoresGeneral.Tmpe.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.Tmpe.formato}}</span> R.I.
                                        <span v-show="!indicadoresGeneral.Tmpe.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.Tmpe.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                    <td class="text-center"><span id="Tmpt" :class="(!indicadoresGeneral.Tmpt.validado)?'noActualizado':'actualizado'">{{indicadoresGeneral.Tmpt.formato}}</span> R.I.
                                        <span v-show="!indicadoresGeneral.Tmpt.validado">
                                            <a class="ml-1 iconoMesajeNoActualizado"
                                                :content="`Valor no actualizado con su sistema (${indicadoresGeneral.Tmpt.formatoBD})`"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
    
    import Vue from 'vue'
    import Multiselect from 'vue-multiselect'
    import cons from '@/const'
    import axios from "axios"
    import { freeSet } from '@coreui/icons'
    import * as util from '@/views/utils/utilsFunctions'

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import { format, formatDistance, formatRelative, subDays, addMonths } from 'date-fns'
    import es from 'date-fns/locale/es'
    
    
    //se muestra la info obtenida desde la api
export default {
    name: "IndicadoresPV",
        freeSet,
    components: {
        Multiselect,
        DatePicker  
    },
    data () {
        return {
            urlBase: cons.port+"://" + cons.ipServer,
            tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
            idCliente: JSON.parse(this.$cookie.get('userLogginToken')).idCliente,
            aniosPeriodo: [],
            mesSelected: null,
            anioSelected: null,

            mesSelected2: new Date(),
            langRetencionesPicker: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
                monthBeforeYear: false,
                formatLocale: {
                    // MMMM
                    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                    // MMM
                    monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                    // dddd
                    weekdays: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
                    // ddd
                    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
                    // dd
                    weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                    
                },
            },

            habilitadoTraspaso: false,

            isLoadingAnios: false,
            isLoadingMes: false,
            isCargandoInfo: false,
            isAcualizandoIndices: false,

            indicadoresGeneral: {
                "fechaUFactual": {"propApi": "UFDescPeriodo", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "str", "numDec":0, "fltr": ""},
                "UFactual": {"propApi": "UFValPeriodo", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 2, "fltr": "dosDecimales"},
                "fechaUFanterior": {"propApi": "UFDescPeridoAnt", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "str", "numDec": 0, "fltr": ""},
                "UFanterior": {"propApi": "UFValPeriodoAnt", "sinFormato": "-", "formato": "-", "validado": false, "tipo": "num", "numDec": 2, "fltr": "dosDecimales"},
                "UTMfec": {"propApi": "UTMDesc", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "str", "numDec": 0, "fltr": ""},
                "UTM": {"propApi": "UTMVal", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "UTA": {"propApi": "UTAVal", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec":0, "fltr": "currency"},
                "RTI1": {"propApi": "RTIAfpUF", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "str", "numDec":0, "fltr": ""},
                "PRTI1": {"propApi": "RTIAfpPesos", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "RTI2": {"propApi": "RTIIpsUF", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "str", "numDec":0, "fltr": ""},
                "PRTI2": {"propApi": "RTIIpsPesos", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "RTI3": {"propApi": "RTISegCesUF", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "str", "numDec":0, "fltr": ""},
                "PRTI3": {"propApi": "RTISegCesPesos", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "RMI1": {"propApi": "RMITrabDepeInd", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "RMI2": {"propApi": "RMIMen18May65", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "RMI3": {"propApi": "RMITrabCasaPart", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "RMI4": {"propApi": "RMINoRemu", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "APVtopeMensual": {"propApi": "APVTopeMensUF", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "str", "numDec":0, "fltr": ""},
                "APVtopem": {"propApi": "APVTopeMensPesos", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "APVtopeAnual": {"propApi": "APVTopeAnuUF", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "str", "numDec":0, "fltr": ""},
                "APVtopea": {"propApi": "APVTopeAnuPesos", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "DepoUF": {"propApi": "DepConvenidoUF", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "str", "numDec":0, "fltr": ""},
                "DepoUFpesos": {"propApi": "DepConvenidoPesos", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "PIemp": {"propApi": "AFCCpiEmpleador", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 1, "fltr": "currency3"},
                "PItra": {"propApi": "AFCCpiTrabajador", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 1, "fltr": "currency3"},
                "PFemp": {"propApi": "AFCCpfEmpleador", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 1, "fltr": "currency3"},
                "PFtra": {"propApi": "AFCCpfTrabajador", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec":1, "fltr": "currency3"},
                "PInemp": {"propApi": "AFCCpi11Empleador", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 1, "fltr": "currency3"},
                "PIntra": {"propApi": "AFCCpi11Trabajador", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 1, "fltr": "currency3"},
                "Tcpemp": {"propApi": "AFCCpfEmpleador", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 1, "fltr": "currency3"},
                "Tcptra": {"propApi": "AFCCpfTrabajador", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec":1, "fltr": "currency3"},
                "DTasaAFPCa": {"propApi": "AFPCapitalTasaDep", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "STasaAFPCa": {"propApi": "AFPCapitalTasaSIS", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "ITasaAFPCa": {"propApi": "AFPCapitalTasaInd", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "DTasaAFPCu": {"propApi": "AFPCuprumTasaDep", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "STasaAFPCu": {"propApi": "AFPCuprumTasaSIS", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "ITasaAFPCu": {"propApi": "AFPCuprumTasaInd", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "DTasaAFPH": {"propApi": "AFPHabitatTasaDep", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "STasaAFPH": {"propApi": "AFPHabitatTasaSIS", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "ITasaAFPH": {"propApi": "AFPHabitatTasaInd", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "DTasaAFPPV": {"propApi": "AFPPlanVitalTasaDep", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "STasaAFPPV": {"propApi": "AFPPlanVitalTasaSIS", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "ITasaAFPPV": {"propApi": "AFPPlanVitalTasaInd", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "DTasaAFPP": {"propApi": "AFPProVidaTasaDep", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "STasaAFPP": {"propApi": "AFPProVidaTasaSIS", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "ITasaAFPP": {"propApi": "AFPProVidaTasaInd", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "DTasaAFPM": {"propApi": "AFPModeloTasaDep", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "STasaAFPM": {"propApi": "AFPModeloTasaSIS", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "ITasaAFPM": {"propApi": "AFPModeloTasaInd", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "DTasaAFPU": {"propApi": "AFPUnoTasaDep", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "STasaAFPU": {"propApi": "AFPUnoTasaSIS", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "ITasaAFPU": {"propApi": "AFPUnoTasaInd", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency2"},
                "MontoAFa": {"propApi": "AFamTramoAMonto", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "MontoAFb": {"propApi": "AFamTramoBMonto", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "MontoAFc": {"propApi": "AFamTramoCMonto", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "MontoAFd": {"propApi": "AFamTramoDMonto", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "str", "numDec":0, "fltr": ""},
                "Primont": {"propApi": "AFamTramoAHasta", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "Primontb": {"propApi": "AFamTramoBDesde", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "Secmont": {"propApi": "AFamTramoBHasta", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "Secmontb": {"propApi": "AFamTramoCDesde", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "Termont": {"propApi": "AFamTramoCHasta", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "Termontb": {"propApi": "AFamTramoDDesde", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency"},
                "Tpc": {"propApi": "TrabPesadoCalif", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency1"},
                "Tpe": {"propApi": "TrabPesadoEmpl", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency1"},
                "Tpt": {"propApi": "TrabPesadoTrabaj", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency1"},
                "Tmpc": {"propApi": "TrabMenosPesadoCalif", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency1"},
                "Tmpe": {"propApi": "TrabMenosPesadoEmpl", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency1"},
                "Tmpt": {"propApi": "TrabMenosPesadoTrabaj", "sinFormato": "-", "formato": "-", "sinFormatoBD": "-", "formatoBD": "-", "validado": false, "tipo": "num", "numDec": 0, "fltr": "currency1"}
            },

            cotAPagar: "",
            fechaIndicador: "",
            factoresEncontrados: null,
            factoresSePuedenValidar: null,

            factoresSinFormato: {
                "fechaUFactual": "-",
                "UFactual": "-",
                "fechaUFanterior": "-",
                "UFanterior": "-",
                "UTMfec": "-",
                "UTM": "-",
                "UTA": "-",
                "RTI1": "-",
                "PRTI1": "-",
                "RTI2": "-",
                "PRTI2": "-",
                "RTI3": "-",
                "PRTI3": "-",
                "RMI1": "-",
                "RMI2": "-",
                "RMI3": "-",
                "RMI4": "-",
                "APVtopeMensual": "-",
                "APVtopem": "-",
                "APVtopeAnual": "-",
                "APVtopea": "-",
                "DepoUF": "-",
                "DepoUFpesos": "-",
                "PIemp": "-",
                "PItra": "-",
                "PFemp": "-",
                "PFtra": "-",
                "PInemp": "-",
                "PIntra": "-",
                "Tcpemp": "-",
                "Tcptra": "-",
                "DTasaAFPCa": "-",
                "STasaAFPCa": "-",
                "ITasaAFPCa": "-",
                "DTasaAFPCu": "-",
                "STasaAFPCu": "-",
                "ITasaAFPCu": "-",
                "DTasaAFPH": "-",
                "STasaAFPH": "-",
                "ITasaAFPH": "-",
                "DTasaAFPPV": "-",
                "STasaAFPPV": "-",
                "ITasaAFPPV": "-",
                "DTasaAFPP": "-",
                "STasaAFPP": "-",
                "ITasaAFPP": "-",
                "DTasaAFPM": "-",
                "STasaAFPM": "-",
                "ITasaAFPM": "-",
                "DTasaAFPU": "-",
                "STasaAFPU": "-",
                "ITasaAFPU": "-",
                "MontoAFa": "-",
                "MontoAFb": "-",
                "MontoAFc": "-",
                "MontoAFd": "-",
                "Primont": "-",
                "Primontb": "-",
                "Secmont": "-",
                "Secmontb": "-",
                "Termont": "-",
                "Termontb": "-",
                "Tpc": "-",
                "Tpe": "-",
                "Tpt": "-",
                "Tmpc": "-",
                "Tmpe": "-",
                "Tmpt": "-"
            },
            previred: {
                "fechaUFactual": "-",
                "UFactual": "-",
                "fechaUFanterior": "-",
                "UFanterior": "-",
                "UTMfec": "-",
                "UTM": "-",
                "UTA": "-",
                "RTI1": "-",
                "PRTI1": "-",
                "RTI2": "-",
                "PRTI2": "-",
                "RTI3": "-",
                "PRTI3": "-",
                "RMI1": "-",
                "RMI2": "-",
                "RMI3": "-",
                "RMI4": "-",
                "APVtopeMensual": "-",
                "APVtopem": "-",
                "APVtopeAnual": "-",
                "APVtopea": "-",
                "DepoUF": "-",
                "DepoUFpesos": "-",
                "PIemp": "-",
                "PItra": "-",
                "PFemp": "-",
                "PFtra": "-",
                "PInemp": "-",
                "PIntra": "-",
                "Tcpemp": "-",
                "Tcptra": "-",
                "DTasaAFPCa": "-",
                "STasaAFPCa": "-",
                "ITasaAFPCa": "-",
                "DTasaAFPCu": "-",
                "STasaAFPCu": "-",
                "ITasaAFPCu": "-",
                "DTasaAFPH": "-",
                "STasaAFPH": "-",
                "ITasaAFPH": "-",
                "DTasaAFPPV": "-",
                "STasaAFPPV": "-",
                "ITasaAFPPV": "-",
                "DTasaAFPP": "-",
                "STasaAFPP": "-",
                "ITasaAFPP": "-",
                "DTasaAFPM": "-",
                "STasaAFPM": "-",
                "ITasaAFPM": "-",
                "DTasaAFPU": "-",
                "STasaAFPU": "-",
                "ITasaAFPU": "-",
                "MontoAFa": "-",
                "MontoAFb": "-",
                "MontoAFc": "-",
                "MontoAFd": "-",
                "Primont": "-",
                "Primontb": "-",
                "Secmont": "-",
                "Secmontb": "-",
                "Termont": "-",
                "Termontb": "-",
                "Tpc": "-",
                "Tpe": "-",
                "Tpt": "-",
                "Tmpc": "-",
                "Tmpe": "-",
                "Tmpt": "-"
            },

            factoresValidados: {},

            tooltipAnio: false,
        }
    },
    beforeMount() {
        //this.getIndicadores()
        this.generarPeriodo()
        //this.llenarObjetoGeneral()
    },
  
    methods: {
        seleccionarPeriodo: function(){
            
            this.generarPeriodo()
        },
        disabledMes: function(date, currentValue){
            let fechaHoy = new Date()
            if(date > fechaHoy)
                return true

            return false
        },
        actualizarFactores: function(){
            this.isAcualizandoIndices = true
            let busqueda = this.generarPeriodoBusqueda()
            var d = new Date();
            var mesConsulta = busqueda.mesBuscar
            var anioConsulta = busqueda.anioBuscar

            /*if(d.getMonth()+1 == this.mesSelected.valor){
                if(d.getDate()<20){
                    mesConsulta = this.mesSelected.valor-1
                    anioConsulta = this.anioSelected.valor
                } else{
                    mesConsulta = this.mesSelected.valor
                    anioConsulta = this.anioSelected.valor
                }
            } else{
                mesConsulta = this.mesSelected.valor
                anioConsulta = this.anioSelected.valor
            }*/


            var url = this.urlBase + "/actualizar_factores"
            axios({
                method: "POST", 
                "url": url,
                "data": {
                    "mes": mesConsulta,
                    "anio": anioConsulta,
                    "actualizarTodoCliente": true,
                    "idCliente": this.idCliente,
                    "tipoBd": "REMU",
                    "factores": this.factoresSinFormato
                },
                "headers": {
                    'Authorization': this.tokenLogin
                }
            }).then(result => {
                this.isAcualizandoIndices = false
                this.generarPeriodo()
                this.$notification.success(result.data.mensajeGeneral, {  timer: 10, position:"bottomRight", showCloseIcn:true});
            }, error => {
                this.isAcualizandoIndices = false
                this.generarPeriodo()
                this.$notification.warning(error.response.data.mensajeGeneral, {  timer: 10, position:"bottomRight", showCloseIcn:true});
            })

        },
        generarPeriodoBusqueda: function(){
            
            let fechaHoy = new Date()//new Date()//new Date("2023-12-02")
            let periodoBuscar = null
            let mesBuscar = null
            let anioBuscar = null

            let pagarEn = null
            let remuPara = null

            if(fechaHoy.getFullYear() == this.mesSelected2.getFullYear() && fechaHoy.getMonth() == this.mesSelected2.getMonth() && fechaHoy.getDate()<20){
                this.habilitadoTraspaso = false
                let fechaMenosUno = addMonths(this.mesSelected2, -1)
                mesBuscar = parseInt(format(fechaMenosUno, "MM", {locale: es}))
                anioBuscar = parseInt(format(fechaMenosUno, "yyyy", {locale: es}))

                periodoBuscar = `${format(fechaMenosUno, "MM", {locale: es})}${format(fechaMenosUno, "yyyy", {locale: es})}`
                
                pagarEn = format(this.mesSelected2, "MMMM yyyy", {locale: es})
                remuPara = format(addMonths(this.mesSelected2, -1), "MMMM yyyy", {locale: es})
            } else{
                this.habilitadoTraspaso = true
                
                mesBuscar = parseInt(format(this.mesSelected2, "MM", {locale: es}))
                anioBuscar = parseInt(format(this.mesSelected2, "yyyy", {locale: es}))

                periodoBuscar = format(this.mesSelected2, "MMyyyy", {locale: es})
                
                pagarEn = format(addMonths(this.mesSelected2, 1), "MMMM yyyy", {locale: es})
                remuPara = format(this.mesSelected2, "MMMM yyyy", {locale: es})
            }

            this.cotAPagar = `Cotizaciones a Pagar en ${pagarEn} (Remuneraciones ${remuPara})`

            return {
                "periodoBuscar": periodoBuscar,
                "mesBuscar": mesBuscar,
                "anioBuscar":anioBuscar
            }

        },
        
        generarPeriodo: function(){
            
            this.isCargandoInfo = true

            this.factoresEncontrados = null
            this.factoresSePuedenValidar = null
            this.factoresValidados = {}
            
            let busqueda = this.generarPeriodoBusqueda()

            this.fechaIndicador = format(this.mesSelected2, "MMMM yyyy", {locale: es})


            
            //this.getFactores(parseInt(format(this.mesSelected2, "MM", {locale: es})), parseInt(format(this.mesSelected2, "yyyy", {locale: es})), busqueda.periodoBuscar)
            this.getFactores(busqueda.mesBuscar, busqueda.anioBuscar, busqueda.periodoBuscar)
            
            //this.rellenarPerdiodos()

            
        },

        getFactores(mes, anio, periodo){
            
            var url = this.urlBase + "/get_factores"
            
            axios({
                method: "POST", 
                "url": url,
                "data": {
                    "info": {
                        "mes": mes,
                        "anio": anio,
                        "actualizarTodoCliente": true,
                        "idCliente": this.idCliente,
                        "tipoBd": "REMU"
                    }
                },
                "headers": {
                    'Authorization': this.tokenLogin
                }
            }
            ).then(result => {
                this.factoresEncontrados = result.data.indicesEncontrados
                this.getIndicadores(periodo)
            }, error => {
                this.factoresSePuedenValidar = error.response.data.factoresPuedenValidar
                this.$notification.warning(error.response.data.mensajeGeneral, {  timer: 10, position:"bottomRight", showCloseIcn:true});
                
                this.getIndicadores(periodo)
            })
        },

        getIndicadores: function(periodo){
            let periodoBusqueda = this.generarPeriodoBusqueda()
            var url = "https://api.gael.cloud/general/public/previred/" + periodoBusqueda.periodoBuscar
            
            axios({
                method: "GET", 
                "url": url,
            }).then(result => {
                //this.factoresSinFormato = result.data
                //this.indicadoresGeneral.fechaUFactual.sinFormato = 
                for (let propiedad in this.indicadoresGeneral) {
                    this.indicadoresGeneral[propiedad].sinFormato = result.data[this.indicadoresGeneral[propiedad].propApi]
                    //Vue.filter(tipoFiltro)(valor)'currency':'soloNumeros'dosDecimales
                    if(this.indicadoresGeneral[propiedad].tipo == "num"){
                        this.indicadoresGeneral[propiedad].formato = Vue.filter(this.indicadoresGeneral[propiedad].fltr)(result.data[this.indicadoresGeneral[propiedad].propApi].replace(",","."))

                    }
                    else if(this.indicadoresGeneral[propiedad].tipo == "str"){
                        this.indicadoresGeneral[propiedad].formato = result.data[this.indicadoresGeneral[propiedad].propApi]
                    }
                    
                }


                this.factoresSinFormato.fechaUFactual = (result.data.UFDescPeriodo)
                this.factoresSinFormato.UFactual = result.data.UFValPeriodo
                this.factoresSinFormato.fechaUFanterior = result.data.UFDescPeridoAnt
                this.factoresSinFormato.UFanterior = result.data.UFValPeriodoAnt
                this.factoresSinFormato.UTMfec = (result.data.UTMDesc)
                this.factoresSinFormato.UTM = result.data.UTMVal
                this.factoresSinFormato.UTA = result.data.UTAVal
                this.factoresSinFormato.RTI1 = (result.data.RTIAfpUF)
                this.factoresSinFormato.PRTI1 = result.data.RTIAfpPesos
                this.factoresSinFormato.RTI2 = (result.data.RTIIpsUF)
                this.factoresSinFormato.PRTI2 = result.data.RTIIpsPesos
                this.factoresSinFormato.RTI3 = (result.data.RTISegCesUF)
                this.factoresSinFormato.PRTI3 = result.data.RTISegCesPesos
                this.factoresSinFormato.RMI1 = result.data.RMITrabDepeInd
                this.factoresSinFormato.RMI2 = result.data.RMIMen18May65
                this.factoresSinFormato.RMI3 = result.data.RMITrabCasaPart
                this.factoresSinFormato.RMI4 = result.data.RMINoRemu
                this.factoresSinFormato.APVtopeMensual = (result.data.APVTopeMensUF)
                this.factoresSinFormato.APVtopem = result.data.APVTopeMensPesos
                this.factoresSinFormato.APVtopeAnual = (result.data.APVTopeAnuUF)
                this.factoresSinFormato.APVtopea = result.data.APVTopeAnuPesos
                this.factoresSinFormato.DepoUF = (result.data.DepConvenidoUF)
                this.factoresSinFormato.DepoUFpesos = result.data.DepConvenidoPesos
                this.factoresSinFormato.PIemp = (result.data.AFCCpiEmpleador)
                this.factoresSinFormato.PItra = (result.data.AFCCpiTrabajador)
                this.factoresSinFormato.PFemp = (result.data.AFCCpfEmpleador)
                this.factoresSinFormato.PFtra = (result.data.AFCCpfTrabajador)
                this.factoresSinFormato.PInemp = (result.data.AFCCpi11Empleador)
                this.factoresSinFormato.PIntra = (result.data.AFCCpi11Trabajador)
                this.factoresSinFormato.Tcpemp = (result.data.AFCCpfEmpleador)
                this.factoresSinFormato.Tcptra = (result.data.AFCCpfTrabajador)
                this.factoresSinFormato.DTasaAFPCa = (result.data.AFPCapitalTasaDep)
                this.factoresSinFormato.STasaAFPCa = (result.data.AFPCapitalTasaSIS)
                this.factoresSinFormato.ITasaAFPCa = (result.data.AFPCapitalTasaInd)
                this.factoresSinFormato.DTasaAFPCu = (result.data.AFPCuprumTasaDep)
                this.factoresSinFormato.STasaAFPCu = (result.data.AFPCuprumTasaSIS)
                this.factoresSinFormato.ITasaAFPCu = (result.data.AFPCuprumTasaInd)
                this.factoresSinFormato.DTasaAFPH = (result.data.AFPHabitatTasaDep)
                this.factoresSinFormato.STasaAFPH = (result.data.AFPHabitatTasaSIS) 
                this.factoresSinFormato.ITasaAFPH = (result.data.AFPHabitatTasaInd)
                this.factoresSinFormato.DTasaAFPPV = (result.data.AFPPlanVitalTasaDep)
                this.factoresSinFormato.STasaAFPPV = (result.data.AFPPlanVitalTasaSIS)
                this.factoresSinFormato.ITasaAFPPV = (result.data.AFPPlanVitalTasaInd)
                this.factoresSinFormato.DTasaAFPP = (result.data.AFPProVidaTasaDep)
                this.factoresSinFormato.STasaAFPP = (result.data.AFPProVidaTasaSIS)
                this.factoresSinFormato.ITasaAFPP = (result.data.AFPProVidaTasaInd)
                this.factoresSinFormato.DTasaAFPM = (result.data.AFPModeloTasaDep)
                this.factoresSinFormato.STasaAFPM = (result.data.AFPModeloTasaSIS) 
                this.factoresSinFormato.ITasaAFPM = (result.data.AFPModeloTasaInd)
                this.factoresSinFormato.DTasaAFPU = (result.data.AFPUnoTasaDep)
                this.factoresSinFormato.STasaAFPU = (result.data.AFPUnoTasaSIS) 
                this.factoresSinFormato.ITasaAFPU = (result.data.AFPUnoTasaInd)
                this.factoresSinFormato.MontoAFa = result.data.AFamTramoAMonto
                this.factoresSinFormato.MontoAFb = result.data.AFamTramoBMonto
                this.factoresSinFormato.MontoAFc = result.data.AFamTramoCMonto
                this.factoresSinFormato.MontoAFd = result.data.AFamTramoDMonto
                this.factoresSinFormato.Primont = result.data.AFamTramoAHasta
                this.factoresSinFormato.Primontb = result.data.AFamTramoBDesde
                this.factoresSinFormato.Secmont = result.data.AFamTramoBHasta
                this.factoresSinFormato.Secmontb = result.data.AFamTramoCDesde
                this.factoresSinFormato.Termont = result.data.AFamTramoCHasta
                this.factoresSinFormato.Termontb = result.data.AFamTramoDDesde
                this.factoresSinFormato.Tpc = (result.data.TrabPesadoCalif)
                this.factoresSinFormato.Tpe = (result.data.TrabPesadoEmpl)  
                this.factoresSinFormato.Tpt = (result.data.TrabPesadoTrabaj)  
                this.factoresSinFormato.Tmpc = (result.data.TrabMenosPesadoCalif)  
                this.factoresSinFormato.Tmpe = (result.data.TrabMenosPesadoEmpl)  
                this.factoresSinFormato.Tmpt = (result.data.TrabMenosPesadoTrabaj)

                this.previred.fechaUFactual = (result.data.UFDescPeriodo)
                this.previred.UFactual = " $"+this.format(result.data.UFValPeriodo.replace(",","."),2)
                this.previred.fechaUFanterior = (result.data.UFDescPeridoAnt) 
                this.previred.UFanterior = " $"+this.format(result.data.UFValPeriodoAnt.replace(",","."),2)
                this.previred.UTMfec = (result.data.UTMDesc)
                this.previred.UTM = " $"+this.format(result.data.UTMVal.replace(",","."))
                this.previred.UTA = " $"+this.format(result.data.UTAVal.replace(",","."))
                this.previred.RTI1 = (result.data.RTIAfpUF)
                this.previred.PRTI1 = " $"+this.format(result.data.RTIAfpPesos.replace(",","."))
                this.previred.RTI2 = (result.data.RTIIpsUF)
                this.previred.PRTI2 = " $"+this.format(result.data.RTIIpsPesos.replace(",","."))
                this.previred.RTI3 = (result.data.RTISegCesUF)
                this.previred.PRTI3 = " $"+this.format(result.data.RTISegCesPesos.replace(",","."))
                this.previred.RMI1 = " $"+this.format(result.data.RMITrabDepeInd.replace(",","."))
                this.previred.RMI2 = " $"+this.format(result.data.RMIMen18May65.replace(",","."))
                this.previred.RMI3 = " $"+this.format(result.data.RMITrabCasaPart.replace(",","."))
                this.previred.RMI4 = " $"+this.format(result.data.RMINoRemu.replace(",","."))
                this.previred.APVtopeMensual = (result.data.APVTopeMensUF)
                this.previred.APVtopem = " $"+this.format(result.data.APVTopeMensPesos.replace(",","."))
                this.previred.APVtopeAnual = (result.data.APVTopeAnuUF)
                this.previred.APVtopea = " $"+this.format(result.data.APVTopeAnuPesos.replace(",","."))
                this.previred.DepoUF = (result.data.DepConvenidoUF)
                this.previred.DepoUFpesos = " $"+this.format(result.data.DepConvenidoPesos.replace(",","."))
                this.previred.PIemp = (result.data.AFCCpiEmpleador)+"%"
                this.previred.PItra = (result.data.AFCCpiTrabajador)+"%"
                this.previred.PFemp = (result.data.AFCCpfEmpleador)+"%"
                this.previred.PFtra = (result.data.AFCCpfTrabajador)
                this.previred.PInemp = (result.data.AFCCpi11Empleador)+"%"
                this.previred.PIntra = (result.data.AFCCpi11Trabajador)
                this.previred.Tcpemp = (result.data.AFCCpfEmpleador)+"%"
                this.previred.Tcptra = (result.data.AFCCpfTrabajador)
                this.previred.DTasaAFPCa = (result.data.AFPCapitalTasaDep)+"%"
                this.previred.STasaAFPCa = (result.data.AFPCapitalTasaSIS)+"%"
                this.previred.ITasaAFPCa = (result.data.AFPCapitalTasaInd)+"%"
                this.previred.DTasaAFPCu = (result.data.AFPCuprumTasaDep)+"%"
                this.previred.STasaAFPCu = (result.data.AFPCuprumTasaSIS)+"%"
                this.previred.ITasaAFPCu = (result.data.AFPCuprumTasaInd)+"%"
                this.previred.DTasaAFPH = (result.data.AFPHabitatTasaDep)+"%"
                this.previred.STasaAFPH = (result.data.AFPHabitatTasaSIS)+"%" 
                this.previred.ITasaAFPH = (result.data.AFPHabitatTasaInd)+"%"
                this.previred.DTasaAFPPV = (result.data.AFPPlanVitalTasaDep)+"%"
                this.previred.STasaAFPPV = (result.data.AFPPlanVitalTasaSIS)+"%"
                this.previred.ITasaAFPPV = (result.data.AFPPlanVitalTasaInd)+"%"
                this.previred.DTasaAFPP = (result.data.AFPProVidaTasaDep)+"%"
                this.previred.STasaAFPP = (result.data.AFPProVidaTasaSIS)+"%"
                this.previred.ITasaAFPP = (result.data.AFPProVidaTasaInd)+"%"
                this.previred.DTasaAFPM = (result.data.AFPModeloTasaDep)+"%"
                this.previred.STasaAFPM = (result.data.AFPModeloTasaSIS)+"%" 
                this.previred.ITasaAFPM = (result.data.AFPModeloTasaInd)+"%"
                this.previred.DTasaAFPU = (result.data.AFPUnoTasaDep)+"%"
                this.previred.STasaAFPU = (result.data.AFPUnoTasaSIS)+"%" 
                this.previred.ITasaAFPU = (result.data.AFPUnoTasaInd)+"%"
                this.previred.MontoAFa = " $"+this.format(result.data.AFamTramoAMonto)
                this.previred.MontoAFb = " $"+this.format(result.data.AFamTramoBMonto) 
                this.previred.MontoAFc = " $"+this.format(result.data.AFamTramoCMonto) 
                this.previred.MontoAFd = (result.data.AFamTramoDMonto) 
                this.previred.Primont = " $"+this.format(result.data.AFamTramoAHasta)
                this.previred.Primontb = " $"+this.format(result.data.AFamTramoBDesde) 
                this.previred.Secmont = " $"+this.format(result.data.AFamTramoBHasta)
                this.previred.Secmontb = " $"+this.format(result.data.AFamTramoCDesde) 
                this.previred.Termont = " $"+this.format(result.data.AFamTramoCHasta)
                this.previred.Termontb = " $"+this.format(result.data.AFamTramoDDesde)
                this.previred.Tpc = (result.data.TrabPesadoCalif)+"%"
                this.previred.Tpe = (result.data.TrabPesadoEmpl)+"%"  
                this.previred.Tpt = (result.data.TrabPesadoTrabaj)+"%"  
                this.previred.Tmpc = (result.data.TrabMenosPesadoCalif)+"%"  
                this.previred.Tmpe = (result.data.TrabMenosPesadoEmpl)+"%"  
                this.previred.Tmpt = (result.data.TrabMenosPesadoTrabaj)+"%"
                this.validarInfo()
                this.isCargandoInfo = false
            }, error => {
              this.isCargandoInfo = false
                
            })
            .finally(() => {
                this.isCargandoInfo = false
                
            });
        },
        validarInfo: function(){
            var validados = {}

            if(this.factoresEncontrados != null){
                for (let key in this.factoresEncontrados) {
                    if(key == "anio" || key == "mes"){
                        continue
                    }
                    this.formatearValores(key)

                    if(this.factoresEncontrados[key] == null){
                        validados[key] = true
                        this.indicadoresGeneral[key].validado = true
                    } else{
                        if(parseFloat(String(this.factoresEncontrados[key]).replace(",",".")) == parseFloat(String(this.factoresSinFormato[key]).replace(",","."))){
                            validados[key] = true
                            this.indicadoresGeneral[key].validado = true                            
                        }else{
                            validados[key] = false
                            this.indicadoresGeneral[key].validado = false
                            
                            //this.indicadoresGeneral[key].formatoBD
                        }
                    }
                }
                this.factoresValidados = validados
            } else{
                if(this.factoresSePuedenValidar != null){
                    for (let key in this.factoresSinFormato) {
                        if(this.factoresSePuedenValidar.includes(key)){
                            this.factoresValidados[key] = false
                            this.indicadoresGeneral[key].validado = false
                        } else{
                            this.factoresValidados[key] = true
                            this.indicadoresGeneral[key].validado = true
                        }
                    }
                }
            }
        },
        formatearValores: function(key){
            if(this.factoresEncontrados[key] == null){
                return 
            }

            if(this.indicadoresGeneral[key].tipo == "num"){
                this.indicadoresGeneral[key].formatoBD = Vue.filter(this.indicadoresGeneral[key].fltr)(this.factoresEncontrados[key].replace(",","."))
            }
            else if(this.indicadoresGeneral[key].tipo == "str"){
                this.indicadoresGeneral[key].formatoBD = this.factoresEncontrados[this.indicadoresGeneral[key].propApi]
            }
        },
        format: function(amount, decimals,separadorMiles,separadorDecimal) {
            var sepMiles = separadorMiles;
            var sepDecimal = separadorDecimal;
            if(typeof separadorMiles === "undefined"){
                sepMiles = ".";
            }
            if(typeof separadorDecimal === "undefined"){
                sepDecimal = ",";
            }
            amount += ''; // por si pasan un numero en vez de un string
            /* eslint-disable-next-line */
            amount = parseFloat(amount.replace(/[^0-9\.]/g, '')); // elimino cualquier cosa que no sea numero o punto
            decimals = decimals || 0; // por si la variable no fue fue pasada
            // si no es un numero o es igual a cero retorno el mismo cero
            if (isNaN(amount) || amount === 0) 
                return parseFloat(0).toFixed(decimals);
            // si es mayor o menor que cero retorno el valor formateado como numero
            amount = '' + amount.toFixed(decimals);
            var amount_parts = amount.split(sepMiles),
                regexp = /(\d+)(\d{3})/;
            while (regexp.test(amount_parts[0]))
                amount_parts[0] = amount_parts[0].replace(regexp, '$1' + sepMiles + '$2');
            return amount_parts.join(sepDecimal);
        }

    }
};
</script>

<style scope>
.noActualizado{
    color: red;
}
.actualizado{
    color: #3c4b64;
}
.iconoMesajeNoActualizado{
    color: red !important;
    text-decoration: none !important;
}

/*:class="(!factoresValidados.PRTI1)?'noActualizado':'actualizado'"*/
</style>
 